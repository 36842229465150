import mfes from '../microfrontends/microfrontends.config.json';
import { Microfrontend } from './app/microfrontends/microfrontend';

const staticMfes = mfes as Microfrontend[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let microfrontends: Microfrontend[] = [];

export const getMicrofrontends = (): Microfrontend[] => {
  return microfrontends;
};

export const setMicrofrontends = (mfes: Microfrontend[]): void => {
  microfrontends = mfes;
};

export async function loadMicrofrontendConfiguration(): Promise<Microfrontend[]> {
  try {
    const response = await fetch('/static/microfrontends.config.json');
    if (response.ok) {
      return response.json().then((res: Microfrontend[]) => {
        const mfes = res;
        return mfes;
      });
    } else {
      return staticMfes;
    }
  } catch (error) {
    return staticMfes;
  }
}
