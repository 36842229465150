import { loadRemoteEntry } from '@angular-architects/module-federation';
import { Microfrontend } from './app/microfrontends/microfrontend';
import { loadMicrofrontendConfiguration, setMicrofrontends } from './microfrontends';

// Load MFEs first, then bootstrap the application
loadMicrofrontends().then((modules) => {
  setMicrofrontends(modules);
  import('./bootstrap').catch((err) => console.error(err));
});

// Load the Webpack 5 Modules
async function loadMicrofrontends() {
  const configurations: Microfrontend[] = await loadMicrofrontendConfiguration();
  const fetchRequests = configurations.map((config) => {
    const { remoteEntryUrl } = config;

    return loadRemoteEntry({ type: 'module', remoteEntry: remoteEntryUrl })
      .then(() => config)
      .catch((e) => console.warn(e));
  });

  // Try to load them all, but ignore the ones that weren't found. Especially useful in local development.
  return Promise.all(fetchRequests).then((modules) => modules.filter(Boolean) as Microfrontend[]);
}
